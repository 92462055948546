import { BrowserRouter, Route, Routes } from "react-router-dom";
import Checkout from "./pages";

function App() {
  return (
    <BrowserRouter basename="/app">
      <Routes>
        <Route path="/:code" Component={Checkout} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
