import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import useRequest from "../hooks/useRequest";
import { useParams } from "react-router-dom";

export default function Checkout() {
  const [payload, setPayload] = useState();

  const code = useParams();
  const [getTransactionDetails, { loading, data }] = useRequest(
    {
      path: "/dev/payment/find-transaction",
      method: "get",
    },
    {
      onCompleted: (d) => {
        setPayload(d?.data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    getTransactionDetails({ code: code.code });
  }, []);

  return (
    <Fragment>
      <main>
        <div class="relative isolate pt-20">
          <div
            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
            aria-hidden="true"
          >
            <div
              className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#56c920] to-[#faa823] opacity-10 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem] background-animate"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 17.7% 2%, 72.5% 32.5%, 60.2% 22.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 10.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#fa2323] to-[#faaf23]  opacity-10 background-animate"
              style={{
                clipPath:
                  "polygon(30.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 10.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 50% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            ></div>
          </div>

          <div
            class="absolute left-0  top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#faa823] to-[#faa823] opacity-10 background-animate"
              style={{
                clipPath:
                  "polygon(30.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 10.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 50% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            ></div>
          </div>
          <div className="mx-auto max-w-2xl bg-white shadow-sm border border-gray-100 rounded-md  p-10 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-60">
            {loading ? (
              <div
                class="animate-spin mx-auto flex inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-pink-600 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <div>
                <div className="text-center ">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Payment of
                  </h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    GHS {payload?.amount + payload?.fees}
                  </p>
                </div>
                <div className="mt-10">
                  <fieldset>
                    <legend className="mb-5 mt-5">Pay With</legend>
                    <div class="-space-y-px rounded-md ">
                      {/* <!-- Checked: "z-10 border-indigo-200 bg-indigo-50", Not Checked: "border-gray-200" --> */}
                      <label class="rounded-tl-md rounded-tr-md relative flex cursor-pointer border p-4 focus:outline-none">
                        <span class="ml-3 flex flex-col flex-1">
                          {/* <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" --> */}
                          <span
                            id="privacy-setting-0-label"
                            class="block text-sm font-medium"
                          >
                            Mobile Money
                          </span>
                          {/* <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" --> */}
                        </span>
                        <div class="flex flex-shrink-0 -space-x-1">
                          <img
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            src="https://citinewsroom.com/wp-content/uploads/2020/03/MTN-Momo-e1584721116128.jpeg"
                            alt="Dries Vincent"
                          />
                          <img
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            src="https://www.backend.myjoyonline.com/wp-content/uploads/2020/06/Vodafone-Cash.png"
                            alt="Lindsay Walton"
                          />
                          <img
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            src="https://newsghana.com.gh/wp-content/uploads/2019/04/airteltigo-money-logo.png"
                            alt="Courtney Henry"
                          />
                        </div>
                      </label>
                      {/* <!-- Checked: "z-10 border-indigo-200 bg-indigo-50", Not Checked: "border-gray-200" --> */}
                      <label class="relative flex cursor-pointer border p-4 focus:outline-none">
                        <span class="ml-3 flex flex-col align-center flex-1">
                          {/* <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" --> */}
                          <span
                            id="privacy-setting-1-label"
                            class="block text-sm font-medium"
                          >
                            Tap n' Go Card
                          </span>
                          {/* <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" --> */}
                        </span>
                        <img
                          class="h-6 w-6 rounded-full"
                          src="https://www.tapngogh.com/static/media/tapngo.75c4e727.png"
                          alt=""
                        />
                      </label>
                      {/* <!-- Checked: "z-10 border-indigo-200 bg-indigo-50", Not Checked: "border-gray-200" --> */}
                      <label class="rounded-bl-md rounded-br-md relative flex cursor-pointer border p-4 focus:outline-none">
                        <span class="ml-3 flex flex-col flex-1">
                          {/* <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" --> */}
                          <span
                            id="privacy-setting-2-label"
                            class="block text-sm font-medium"
                          >
                            Bank Card
                          </span>
                          {/* <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" --> */}
                        </span>
                        <div class="flex flex-shrink-0 -space-x-1">
                          <img
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            src="https://blog.logomyway.com/wp-content/uploads/2022/02/visa-logo-2.jpg"
                            alt="Dries Vincent"
                          />
                          <img
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/2560px-MasterCard_Logo.svg.png"
                            alt="Lindsay Walton"
                          />
                        </div>
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div>
                  <table className="mt-10 w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                      <col className="w-full" />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead className="border-b border-gray-200 text-gray-900">
                      <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                          Item
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        ></th>
                        <th
                          scope="col"
                          className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        ></th>
                        <th
                          scope="col"
                          className="py-3 pl-8 pr-0 text-right font-semibold"
                        >
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-gray-100">
                        <td className="max-w-0 px-0 py-5 align-top">
                          {/* <div className="truncate font-medium text-gray-900">
                            Title
                          </div> */}
                          <div className="truncate text-gray-500">
                            {payload?.description}
                          </div>
                        </td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell"></td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell"></td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          GHS {payload?.amount}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th
                          scope="row"
                          className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
                        >
                          Subtotal
                        </th>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                        >
                          Subtotal
                        </th>
                        <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                          GHS {payload?.amount}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="pt-4 font-normal text-gray-700 sm:hidden"
                        >
                          Charges
                        </th>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                        >
                          Charges
                        </th>
                        <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                          GHS {payload?.fees}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="pt-4 font-semibold text-gray-900 sm:hidden"
                        >
                          Total
                        </th>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                        >
                          Total
                        </th>
                        <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                          GHS {payload?.fees + payload?.amount}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            )}

            <svg
              class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-100 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                  width="200"
                  height="200"
                  x="50%"
                  y="-1"
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path
                  d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                  stroke-width="0"
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                stroke-width="0"
                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
              />
            </svg>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
